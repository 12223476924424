import React from "react";
import Meta from "./../components/Meta";
import QueueSection from "./../components/QueueSection";
import { requireAuth } from "./../util/auth";
import { useRouter } from "./../util/router";

function QueuePage(props) {
  const router = useRouter();

  return (
    <>
      <Meta title={"Queue " + router.query.ghuser + "/" + router.query.repo} />
      <QueueSection
        color="white"
        size="medium"
        title={router.query.ghuser}
        subtitle={router.query.repo}
        queue={router.query.repo}
      />
    </>
  );
}

export default requireAuth(QueuePage);
