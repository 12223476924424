import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Jobs from "./Jobs";
import { Link, useRouter } from "./../util/router";
import { useAuth } from "./../util/auth";
import "./DashboardSection.scss";
import { useQuery } from 'react-query';

function QueueSection(props) {
  const auth = useAuth();
  const router = useRouter();

  const { isLoading, isError, data : jobData} = useQuery('jobData', async () =>
    {const response = await fetch('https://auth.cronbon.com/jobs/' + auth.user.id + "/" + props.queue, {mode: 'cors', method: 'POST'});
    if (!response.ok) {
      throw new Error('Network response was not ok')
    }
    return response.json();
  });


  return (
    <Section color={props.color} size={props.size}>
      <div className="container">
        <SectionHeader
          title={<tt>{props.subtitle}</tt>}
          subtitle=""
          size={3}
          spaced={true}
          className="has-text-centered"
        />

        <div className="columns is-desktop">
                <div className="column">
                    <div className="card">
                    <div className="card-content">
                        <div className="content">

                            <div className="mt-4">
                                <h4>Repo details</h4>
                                {jobData && <div>
                                    GitHub repo: <a href={"https://github.com/" + jobData.this_queue.split(":")[2]}><strong>{jobData.this_queue.split(":")[2]}</strong></a><br/>
                                    <tt>{jobData && jobData.commit}</tt>
                                </div>}
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
        </div>


        <div className="columns is-desktop">
          <div className="column">
            <Jobs loading={isLoading} items={jobData ? jobData.jobs : []} />
          </div>

        </div>
        {/* {!isLoading && !isError && (
        <div>
          <h1>Debug</h1>
          <p>{JSON.stringify(jobData)}</p>
        </div>)
        } */}
      </div>
    </Section>
  );
}

export default QueueSection;
