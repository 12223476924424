import React, { useState } from "react";
import AddQueueModal from "./AddQueueModal";
import "./DashboardItems.scss";

function Repos(props) {

  const [creatingQueue, setCreatingQueue] = useState(false);

  const items = props.items;
  const itemsAreEmpty = !items || items.length === 0;
  const itemsStatus = items ? "ok" : "loading";
  
  return (
    <>
      <div className="DashboardItems__panel panel">
        <div className="panel-heading has-background-light py-3 px-4 is-flex is-justify-content-space-between is-align-items-center">
          Queues
          <button
            className="button is-primary"
            onClick={() => setCreatingQueue(true)}
          >
            Add
          </button>
        </div>

        {(itemsStatus === "loading" || itemsAreEmpty) && (
          <div className="py-5 px-3">
            {itemsStatus === "loading" && (
              <div className="loader is-loading mx-auto" />
            )}

            {itemsStatus !== "loading" && itemsAreEmpty && (
              <div className="has-text-centered">
                Welcome! Let's create a new queue and a matching GitHub repo containing the code to run. Click Add to get started.
              </div>
            )}
          </div>
        )}

        {itemsStatus !== "loading" && items && items.length > 0 && (
          <>
            {items.map((item, index) => (
              <div key={"queue-" + index}
                className={
                  "panel-block p-3 is-flex is-justify-content-space-between" +
                  (item.featured ? " featured" : "")
                }
                key={index}
              ><a href={"/queue/" + item.split(":")[2].split("/")[0] + "/" + item.split(":")[0]}>
                <p><b>{item.split(":")[0]}</b></p>
                <p><tt>github.com/<b>{item.split(":")[2]}</b></tt></p>
                </a>
                  <button
                    className="button action is-ghost"
                    aria-label="delete"
                    onClick={() => props.handleDeleteItem(item)}
                  >
                    <span className="icon is-small">
                      <i className="fas fa-trash" />
                    </span>
                  </button>
                </div>
            ))}
          </>
        )}
      </div>

      {creatingQueue && 
        <AddQueueModal
            apiKey={props.apiKey}
            github_user={props.repos[0].archive_url.split('/')[4]}
            existingRepos={props.repos}
            onDone={
                () => {
                    setCreatingQueue(false);
                    props.queryClient.invalidateQueries();
                }} />}
    </>
  );
}

export default Repos;
