import React, { useState } from "react";
import { useForm } from "react-hook-form";
import FormAlert from "./FormAlert";
import FormField from "./FormField";
import { useAuth } from "./../util/auth";
import { useItem, updateItem, createItem } from "./../util/db";
import "./EditItemModal.scss";

function AddQueueModal(props) {
  const auth = useAuth();
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);
  const [showRepoName, setShowRepoName] = useState(false);

  const { register, handleSubmit, errors } = useForm();

  const CREATE_REPO = "Create new repo";

  const handleCreateQueue = async (data) => {

    var branch = "main";
    if (data.repo !== CREATE_REPO) {
        for (var i in props.existingRepos) {
            if (props.existingRepos[i].url.indexOf(data.repo) > -1) {
                branch = props.existingRepos[i].default_branch;
                break;
            }
        }
    }

    props.existingRepos.map((repo) => (repo.url.split('github.com/repos/')[1])).concat([CREATE_REPO])

    const response = await fetch('https://auth.cronbon.com/api/queue/create', {mode: 'cors', method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            apiKey: props.apiKey,
            repo_name: data.repo === CREATE_REPO ? data.reponame : data.repo.split("/")[1],
            repo_path: data.repo === CREATE_REPO ? props.github_user + '/' + data.reponame : data.repo,
            create_repo: data.repo === CREATE_REPO ? true : false,
            queue: data.name,
            branch: branch,
        })});
    if (!response.ok) {
      throw new Error(response.status === 402 ? "Free plans only get one job queue" : "There was an error creating the queue");
    }
    return response.json();
  };

  const onSubmit = (data) => {
    setPending(true);
    console.log(data);
    handleCreateQueue(data)
      .then(() => {props.onDone();})
      .catch((error) => {
        // Hide pending indicator
        setPending(false);
        // Show error alert message
        setFormAlert({
          type: "error",
          message: error.message,
        });
      });
  };

  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={() => props.onDone()} />
      <div className="EditItemModal__card card">
        <header className="card-header">
          <p className="card-header-title">
            {props.id && <>Update</>}
            {!props.id && <>Create</>}
            {` `}Queue
          </p>
          <span className="card-header-icon">
            <button
              className="delete"
              aria-label="close"
              onClick={() => props.onDone()}
            >
              Done
            </button>
          </span>
        </header>
        <div className="card-content">
          {formAlert && (
            <FormAlert type={formAlert.type} message={formAlert.message} />
          )}

          <form onSubmit={handleSubmit(onSubmit)}>
              <p>Choose an existing GitHub repo, or create a new one</p>
          <FormField
              name="repo"
              type="select"
              options={props.existingRepos.map((repo) => (repo.url.split('github.com/repos/')[1])).concat([CREATE_REPO])}
              size="medium"
              error={errors.repo}
              autoFocus={true}
              onChange={(e) => {if (e.target.value === CREATE_REPO) {
                  setShowRepoName(true);
              } else {
                  setShowRepoName(false);
                }}}
              inputRef={register({
                required: "Please select a repo",
              })}
            />
            {showRepoName && <FormField
              name="reponame"
              type="text"
              placeholder="Repo Name"
              size="medium"
              error={errors.reponame}
              autoFocus={false}
              inputRef={register({})}
            />}
            <FormField
              name="name"
              type="text"
              placeholder="Queue Name"
              size="medium"
              error={errors.name}
              autoFocus={false}
              inputRef={register({
                required: "Please enter a name",
              })}
            />
            <div className="field">
              <div className="control">
                <button
                  className={
                    "button is-medium is-primary" +
                    (pending ? " is-loading" : "")
                  }
                  type="submit"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddQueueModal;
