import React from "react";
import "./DashboardItems.scss";

function Jobs(props) {

  const items = props.items;
  const itemsAreEmpty = !items || items.length === 0;
  const itemsStatus = items ? "ok" : "loading";
  
  return (
    <>
      <div className="DashboardItems__panel panel">
        <div className="panel-heading has-background-light py-3 px-4 is-flex is-justify-content-space-between is-align-items-center">
          Jobs
        </div>

        {(itemsStatus === "loading" || itemsAreEmpty) && (
          <div className="py-5 px-3">
            {itemsStatus === "loading" && (
              <div className="loader is-loading mx-auto" />
            )}

            {props.loading && (
              <div className="has-text-centered">
                Loading...
              </div>
            )}

            {!props.loading && (
              <div className="has-text-centered">
                No jobs added to this queue yet.
              </div>
            )}
          </div>
        )}

        {itemsStatus !== "loading" && items && items.length > 0 && (
          <>
            <div className="panel-block p-3 is-flex is-justify-content-space-between TableHeader">
                <p>ID</p>
                <p>Type</p>
                <p>Timing</p>
                <p>Function</p>
            </div>
            {items.map((item, index) => (
              <a href={"/queue/" + index}><div
                className={
                  " p-3 is-flex is-justify-content-space-between TableRow" +
                  (item.featured ? " featured" : "")
                }
                key={index}
              >
                <p><b>{item.id.split("_")[3]}</b></p>
                <p>{item.type}</p>
                <p>{item.timing}</p>
                <p>{item.function}</p>
              </div>
              <div className="p-3 is-flex is-justify-content-space-between">
                {item.updates.map((update, index) => {
                    const data = JSON.parse(update.substr(update.indexOf(":") + 1));
                    return data.type === "done" ? <p className="tiny">🟩</p> : <p className="tiny">🟥</p>;
                })}
                </div>
                <div className="panel-block"><p>&nbsp;</p></div>
                </a>
            ))}
          </>
        )}
      </div>
    </>
  );
}

export default Jobs;
