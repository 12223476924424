import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Repos from "./Repos";
import { Link, useRouter } from "./../util/router";
import { useAuth } from "./../util/auth";
import "./DashboardSection.scss";
import { useQuery, useQueryClient } from 'react-query';
import PenguinGame from "./PenguinGame";

function DashboardSection(props) {
  const auth = useAuth();
  const router = useRouter();

  const queryClient = useQueryClient()
  const { isLoading, isError, data : userData} = useQuery('userData', async () =>
    {const response = await fetch('https://auth.cronbon.com/user/' + auth.user.id, {mode: 'cors', method: 'POST'});
    if (!response.ok) {
      throw new Error('Network response was not ok')
    }
    return response.json();
  });

  const handleDeleteQueue = async (queueName) => {

    const response = await fetch('https://auth.cronbon.com/api/queue/delete', {mode: 'cors', method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            apiKey: userData.apiKey,
            queue: queueName
        })});
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    queryClient.invalidateQueries('userData');
    return response.json();
  };


  return (
     userData && (userData.onboarding === "github_not_connected") ?
     <Section color={props.color} size={props.size}>
      <div className="container">
        <SectionHeader
          title="Welcome to cronbon"
          subtitle="Connect your github account to get started..."
          size={3}
          spaced={true}
          className="has-text-centered"
        />
        <div className="columns is-centered is-variable is-4 is-desktop">

        <a
          href={
            userData.github_link
          }
          className=" button is-medium is-primary"
        >
          Link your GitHub account
        </a>
        </div>
        </div>
      </Section>
     :
    <Section color={props.color} size={props.size}>
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={3}
          spaced={true}
          className="has-text-centered"
        />

        {router.query.paid && auth.user.planIsActive && (
          <article className="DashboardSection__paid-message message is-success mx-auto mb-5">
            <div className="message-body">
              You are now subscribed to the {auth.user.planId} plan
              <span className="ml-2" role="img" aria-label="party">
                🥳
              </span>
            </div>
          </article>
        )}

        <div className="columns is-desktop">
          <div className="column">
            <Repos 
                queryClient={queryClient}
                apiKey={userData && userData.apiKey}
                items={userData ? userData.queues : []}
                repos={userData && userData.github_repos ? userData.github_repos : []}
                handleDeleteItem={(item) => {
                  console.log("delete item", item);
                  handleDeleteQueue(item.split(":")[0]);
                }}
                />
          </div>
        </div>

      </div>
    </Section>
  );
}

export default DashboardSection;
