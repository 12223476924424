import React from "react";
import { useState } from "react";
import "./DashboardItems.scss";

const makeRandomFishGrid = (size, numFish) => {
    // Makes a grid of random fish
    let grid = [];
    for (let i = 0; i < size; i++) {
        grid.push([]);
        for (let j = 0; j < size; j++) {
            grid[i].push(null);
        }
    }
    for (let i = 0; i < numFish; i++) {
        let x = Math.floor(Math.random() * size);
        let y = Math.floor(Math.random() * size);
        if (grid[x][y] === "fish" || (x === 0 && y === 0)) {
            // retry
            i--;
        } else {
            grid[x][y] = "fish";
        }
    }
    return grid;
}


function PenguinGame(props) {

  const penguin = "🐧";
  const fish = "🐡";
  const [fishCount, setFishCount] = useState(0);
  const [penguinPosition, setPenguinPosition] = useState([0, 0]);
  const [yum, setYum] = useState(false);

  const [grid, setGrid] = useState(makeRandomFishGrid(props.size, props.numFish));

  const moveAllFishOneSquareLeft = () => {
    let newGrid = grid.map(row => {
        return row.map(cell => {
            if (cell === "fish") {
                return "fish";
            } else {
                return null;
            }
        });
    });
    for (let i = 0; i < newGrid.length; i++) {
        for (let j = 0; j < newGrid[i].length; j++) {
            if (newGrid[i][j] === "fish") {
                if (j === 0) {
                    newGrid[i][newGrid[i].length-1] = "fish";
                    newGrid[i][j] = null;
                } else {
                    newGrid[i][j - 1] = "fish";
                    newGrid[i][j] = null;
                }
            }
        }
    }
    setGrid(newGrid);
    return newGrid;
    };
  
  return (
    <>
      <div className="DashboardItems__panel panel">
        <div className="panel-heading has-background-light py-3 px-4 is-flex is-justify-content-space-between is-align-items-center">
          Fish Fish Penguin  🐟 {fishCount}
        </div>
        {(fishCount === props.numFish) && (
            <div className="panel-block p-3 is-flex">
                <p>You caught all the fish!</p>
            </div>
        )}


        {(fishCount < props.numFish) && (<div className="DashboardItems__grid">
            {grid.map((row, i) => (
                <div className="DashboardItems__row" key={i}>
                    {row.map((cell, j) => (
                        <span
                            className={"DashboardItems__cell" + (yum ? " yum": "")} onMouseDownCapture={(e) => {
                                var pi = penguinPosition[0];
                                var pj = penguinPosition[1];
                                if (i > pi) {
                                    pi += 1;
                                }
                                if (i < pi) {
                                    pi -= 1;
                                }
                                if (j > pj) {
                                    pj += 1;
                                }
                                if (j < pj) {
                                    pj -= 1;
                                }
                                setPenguinPosition([pi, pj]);
                                var nextGrid = moveAllFishOneSquareLeft();

                                var ate = false;
                                if (nextGrid[pi][pj] === "fish") {
                                    setFishCount(fishCount + 1);
                                    ate = true;
                                    nextGrid[pi][pj] = null;
                                    setGrid(nextGrid);
                                }
                                setYum(ate);
                            }}>
                            {i === penguinPosition[0] && j === penguinPosition[1] && penguin}
                            {cell === "fish" && (
                                // <span className="DashboardItems__fish"> <img className="fish" src="/fish.png" /> </span>
                                <span className="DashboardItems__fish"> {fish} </span>
                            )}
                            {(i !== penguinPosition[0] || j !== penguinPosition[1]) && cell === null && (
                                <span className="DashboardItems__empty">&nbsp;</span>
                            )}
                        </span>
                    ))}
                </div>
            ))}
        </div>)}

      </div>
    </>
  );
}

export default PenguinGame;
